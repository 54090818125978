import React from 'react';
import Helmet from 'react-helmet';
import OgImage from 'assets/images/og-img.png';
import getSafe from 'utils/getSafe';

const Entities = require('html-entities').AllHtmlEntities;

const entities = new Entities();

const defaultTitle = 'SplendourXR';
const defaultDescription =
  'SplendourXR';

const Head: React.StatelessComponent<any> = ({
  title,
  metaDesc,
  opengraphDescription,
  opengraphTitle,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  lang = 'EN',
}) => (
  <Helmet
    htmlAttributes={{ amp: true, lang: lang.replace("_", "-") }}
    defaultTitle={entities.decode(title) || defaultTitle}
    title={entities.decode(title) || defaultTitle}
    meta={[
      {
        name: 'description',
        content: entities.decode(metaDesc) || defaultDescription,
      },
      {
        property: 'og:title',
        content: entities.decode(opengraphTitle) || entities.decode(title) || defaultTitle,
      },
      {
        property: 'og:description',
        content: entities.decode(opengraphDescription) || entities.decode(metaDesc) || defaultDescription,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:title',
        content: entities.decode(twitterTitle) || entities.decode(title) || defaultTitle,
      },
      {
        name: 'twitter:description',
        content: entities.decode(twitterDescription) || entities.decode(metaDesc) || defaultDescription,
      },
      {
        property: 'og:image',
        content: (getSafe(() => opengraphImage.sourceUrl) ? opengraphImage.sourceUrl : null) || OgImage,
      },
      {
        property: 'twitter:image',
        content: (getSafe(() => twitterImage.sourceUrl) ? twitterImage.sourceUrl : null) || OgImage,
      },
    ]}
  >
    <link rel="stylesheet" href="https://use.typekit.net/rtx5prg.css" />
    <script async={true} src="https://www.googletagservices.com/tag/js/gpt.js" />
    <script>{tagScript}</script>
  </Helmet>
);

export default Head;

const tagScript = `  
  var googletag = googletag || {};
  googletag.cmd = googletag.cmd || [];
`;
