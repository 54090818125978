type RgbaUtil = (hex: string | undefined, opacity: number) => string

const rgba = (hex: string | undefined, opacity: number): string => {
  if (hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    const rgb = {
      r: result ? parseInt(result[1], 16) : 0,
      g: result ? parseInt(result[2], 16) : 0,
      b: result ? parseInt(result[3], 16) : 0
    }

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
  }

  return ''
}

export type UtilsType = {
  rgba: RgbaUtil
}

export default {
  rgba
} as UtilsType
